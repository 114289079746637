<template>
  <div>
    <h4>My Earnings</h4>
    <alert type="success">
      Share your referral link with others to earn.
      <referral-link />
    </alert>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="earnings.length == 0">
              <td colspan="4">There is no data to display.</td>
            </tr>
            <tr v-for="(earning, i) in earnings" :key="`earning-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ earning.type }}</td>
              <td>{{ earning.amount }}</td>
              <td>{{ earning.date }}</td>
            </tr>
            <tr v-if="earnings.length > 0">
              <td colspan="2" align="right"><strong>Total</strong></td>
              <td colspan="2"><strong>KES {{ earnings_total }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      earnings: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/my-account/earnings`).then(response => {
        this.earnings = response.data.earnings
        this.loading = false
      })
    }
  },

  computed: {
    earnings_total() {
      let total = 0

      this.earnings.forEach(earning => {
        total += earning.amount
      })

      return total
    }
  }
};
</script>
